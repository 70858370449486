<template>
    <div>
        <el-row>
            <el-col :span="18">
                <el-radio-group size="medium" v-model="type" @change="handleType">
                    <el-radio-button :label="null">全部</el-radio-button>
                    <el-radio-button label="CALL_MOBILE">拨打</el-radio-button>
                    <el-radio-button label="ALLOT">转接</el-radio-button>
                    <el-radio-button label="TAG_EDIT">标签</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-row style="height: 82px;border: 1px #979797 outset;" v-for="item in serviceRecordList">
            <el-col :span="4" style="margin-top: 30px;font-size: 25px;text-align: center;">
                <i v-for="se in serviceTypeList" :key="se.value" v-if="se.value === item.type" :class="se.icon"></i>
            </el-col>
            <el-col :span="10" style="font-size: 13px;line-height: 10px;">
                <p>时间:{{ item.createTime }}</p>
                <p v-for="se in serviceTypeList" :key="se.value" v-if="se.value === item.type">操作:{{ se.label }}</p>
                <p>操作人:{{ item.createBy }}</p>
            </el-col>
            <el-col :span="10" style="font-size: 13px;line-height: 10px;">
                <p>{{ item.content }}</p>
            </el-col>
        </el-row>
        <el-row style="text-align: center;font-size: 13px;margin-top: 14px;">
            <el-button size="mini" :disabled="more" @click="findPage">{{ more ? '没有更多了' : '查看更多' }}</el-button>
        </el-row>
    </div>
</template>

<script>
import * as servicesRecordApi from "@/api/waiter/waiterServicesRecord";

export default {
    name: "WaiterStudentServiceRecord",
    data() {
        return {
            params: {
                current: 1,
                size: 10,
                type: null,
            },
            serviceRecordList: [],
            total: 0,
            more: false,
            type: null,
            serviceTypeList: [],
        }
    },
    props: {
        recordPro: {
            type: Object,
            require: true,
            default: {
                studentId: 0
            }
        },
        edit: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.params.type = null
        this.handleType()
        this.findServiceTypeList()
    },
    methods: {
        findServiceTypeList() {
            servicesRecordApi.findTypes().then(res => {
                this.serviceTypeList = res
            })
        },
        handleType(e) {
            this.params = {
                current: 0,
                size: 10,
                type: e,
            }
            this.total = 0
            this.serviceRecordList = []
            this.findPage()
        },
        findPage() {
            this.params.current++
            this.params.studentId = this.recordPro.studentId
            servicesRecordApi.findPage(this.params).then(res => {
                if (res.success) {
                    if (res.data.records) {
                        this.serviceRecordList = this.serviceRecordList.concat(res.data.records)
                        this.total = res.data.total
                        if (this.serviceRecordList.length === this.total) {
                            this.more = true
                        }
                    }
                }
            })
        },
    }
}
</script>

<style scoped>

</style>